.btn {
    border-radius: 50px;
    font-weight: 500;
    box-shadow: 0 -2px 0 0 rgb(24 24 24 / 15%) inset;
    padding: 0.775rem 1.775rem;
    margin-bottom: 0.25em;
    transition: all ease 350ms;
    transform: translateY(0);
    
    &:hover {
        box-shadow: 0 -3px 0 0 rgb(24 24 24 / 15%) inset, 0 3px 10px rgb(0 0 0 / 30%);
        transition: all ease 350ms;
        transform: translateY(-2px);
    }
}