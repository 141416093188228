@mixin phone {
    @media (max-width: 575.98px) {
        @content;
    }
}

@mixin phone-up {
    @media (min-width: 576px) and (max-width: 767.98px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 768px) and (max-width: 991.98px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 992px) and (max-width: 1199.98px) {
        @content;
    }
}

@mixin desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin retina {

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
        @content;
    }
}

@mixin print {
    @media print {
        @content;
    }
}