$font-family-sans-serif: "Work Sans" !default;
.ff-secondary {
    font-family: 'Barlow', sans-serif !important;
}

$display-font-sizes: (
    5: 3.125rem,
);

$font-sizes: (
    7: 0.875rem,
    8: 0.75rem,
);