body {
    overflow-x: hidden !important;
}

section {
    padding: 6rem 0;

    @include phone {
        padding: 2.5em 0;
    }
}

.list-group {
    .list-group-item {
        border: 0;
        background-color: transparent;
    }
}

.post-card {
    position: relative;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    transition: all ease 350ms;
    transform: translateY(0);
    
    &:hover {
        transition: all ease 350ms;
        transform: translateY(-16px);
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);
    }
}

.square:before {
    content: "";
    display: block;
    padding-top: 100%;
}

.post-date {
    width: 80px;
    height: 80px;
    box-shadow: 0 -2px 0 0 rgb(24 24 24 / 15%) inset;
}

.post-grid {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: -1px;
    bottom: 0;
    pointer-events: none;
    z-index: 2;
    background: -webkit-linear-gradient(top, transparent 16%, #fff 56%);
    background: -moz-linear-gradient(top, transparent 16%, #fff 56%);
    background: linear-gradient(to bottom, transparent 16%, #fff 56%);
    opacity: 1;
    
    .post-title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    
    .post-desc {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.blog-detail-title {
    h1 {
        z-index: 999;
    }
}

.fw-semibold {
    font-weight: 600;
}

.social-media {
    width: 56px;
    height: 56px;
    box-shadow: 0 -2px 0 0 rgb(24 24 24 / 15%) inset;
}

.fi-lh-unset:before {
    line-height: unset !important;
}

.fi-16px:before {
    font-size: 1rem;
}

.fi-20px:before {
    font-size: 1.25rem;
}

.fi-24px:before {
    font-size: 1.5rem;
}

.fi-28px:before {
    font-size: 1.75rem;
}

.blog-detail-img {
    width: auto;

    @include phone {
        width: 100%;
    }
}

.masking {
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    position: absolute;
}

.img-partner {
    object-fit: contain;
    filter: grayscale(100%);
    transition: all 250ms ease;
    transform: scale(1);

    &:hover {
        filter: grayscale(0%);
        transition: all 250ms ease;
        transform: scale(1.1);
    }
}

.motto {
    padding: 10rem 0 !important;
}

.box-slide-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
}

.slider-mask {
    background: rgba(0, 0, 0, .52);
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
}

.slider-panel-page {
    width: 120px;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

    .panel-page-line {
        width: 1px;
        height: calc(100% - ((6.5rem + 1.5rem) * 2));
        // background: $white-54;
    }

    .panel-page-mask {
        width: 100%;
        height: 100%;
        // background: $white-10;
        position: absolute;
    }

    .panel-page-dot {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        .dot {
            width: .5rem;
            height: .5rem;
            border-radius: 50%;
            background: white;
            margin-bottom: 1rem;
            transition: all .5s ease;
        }

        .dot.active {
            transition: all .5s ease;
            transform: scale(2);
        }
    }

    @include phone {
        width: 80px;
    }
}

.slick-img {
    width: 100%;
    height: 100vh;
}

.slider-panel-button {
    position: absolute;
    /* bottom: 0; */
    /* padding: 1.5rem 0; */
    left: 50%;
    z-index: 1000;
    top: 50%;
    transform: translate(-50%, -50%);

    @include phone {
        padding: 1rem 0;
    }

    .panel-button-prev,
    .panel-button-next {
        width: 3rem;
        height: 3rem;
        color: $primary;
        background-color: white;
        transition: all 250ms ease;

        &:hover {
            transition: all 250ms ease;
        }

        @include phone {
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}

.section-promo {
    .panel-box-button-prev {
        position: absolute;
        left: -100%;
        transition: all 350ms ease;
    }

    .panel-box-button-next {
        position: absolute;
        right: -100%;
        transition: all 350ms ease;
    }

    &:hover {
        .panel-box-button-prev {
            left: 1.5rem;
            transition: all 350ms ease;

            @include phone {
                bottom: 50%;
                transform: translateY(-80%);
                left: 1rem;
            }
        }
    
        .panel-box-button-next {
            right: 2.5rem;
            transition: all 350ms ease;

            @include phone {
                bottom: 50%;
                transform: translateY(-80%);
                right: 1rem;
            }
        }
    }
}

.quick-panel {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 99;

    .quick-panel1,
    .quick-panel2 {
        padding: 1.5rem 2rem;

        @include phone {
            padding: 1rem;
        }

        img {
            width: 48px;

            @include phone {
                width: 32px;
            }
        }
    }
}

.box-img {
    width: 100%;
    height: 100%;

    img {
        width: 100%;
    }
}

.box-bg-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1920px;

    @include phone {
        width: 1280px;
    }
}

.box-img-rotate {
    width: 100%;
    height: 100%;
    transform: rotate(-15deg);
}

.box-img-text {
    position: absolute;
    top: 0;
}

.float-button {
    position: fixed;
    right: 16px;
    bottom: 24px;
    z-index: 999;
}

.text-icon {
    color: $primary;
    opacity: 0;
    font-size: 14px;
    transition: opacity 250ms ease;
}

.float-button .float-whatsapp {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.25);
    transition: all 150ms ease;

    &:hover {
        width: 196px;
        border-radius: 2rem;
        transition: all 250ms ease;

        .text-icon {
            transition: opacity 1s ease;
            opacity: 1;
        }
    }
}