.navbar {
    height: 125px;
    z-index: 1001;

    @include phone {
        align-items: start;
        height: 72px;
    }
    
    .navbar-brand {
        margin-left: .5rem;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @include phone {
            align-items: start !important;
            height: 72px;
        }

        img {
            transition: all 250ms ease;
            height: 118px;
        }

        .navbar-logo-text {
            transition: all 250ms ease;
            margin-left: 2.5rem;

            .brand-text {
                transition: all 250ms ease;
                font-size: 2.125rem;
                line-height: 1.25;
            }
            .brand-subtext {
                transition: all 250ms ease;
                font-size: 1rem;
            }
        }
    }

    .navbar-nav {
        .nav-item {
            transition: all 250ms ease;
            padding: 56px 0;
            
            a.nav-link {
                transition: all 250ms ease;
                padding: 0 25px;
                color: white;
                font-size: 1rem;
                position: relative;
            }

            .nav-link:before {
                content: "";
                width: 0;
                height: 2px;
                background-color: white;
                position: absolute;
                left: 0;
                top: 50%;
                transition: all 300ms;
            }

            .nav-link.active:before {
                content: "";
                width: 16px;
                height: 2px;
                background-color: white;
                position: absolute;
                left: 0;
                top: 50%;
                transition: all 300ms;
            }

            .nav-link:hover:before {
                content: "";
                width: 16px;
                height: 2px;
                background-color: white;
                position: absolute;
                left: 0;
                top: 50%;
                transition: all 300ms;
            }

            // a.active {
                // color: $third;
            // }
        }

        // .nav-item.active {
        //     a.nav-link {
                // color: $third;
        //     }
        // }
    }

    @include phone {
        padding: 0 1rem;
        .navbar-brand {
            padding: 0;
            img {
                height: 48px;
            }

            .navbar-logo-text {
                margin-left: 1.5rem;

                .brand-text {
                    @include phone {
                        font-size: 1.5rem;
                    }
                }
                .brand-subtext {
                    @include phone {
                        font-size: 0.775rem;
                    }
                }
            }
        }

        .navbar-toggler {
            border-color: transparent;
            border: 0;
            margin-right: 0;
            height: 72px;

            .mdi-menu::before {
                content: "\F035C";
            }
        }

        .navbar-collapse {
            // background: $primary;
            .navbar-nav {
                .nav-item {
                    padding: .5rem 0;
    
                    a.nav-link {
                        padding: 0 20px;
                        font-size: 1rem;
                    }
                }
            }
        }
    }

    @include tablet {
        padding: .5rem 0;
        .navbar-brand {
            img {
                height: 2.5rem;
            }

            .navbar-logo-text {
                transition: all 250ms ease;
                font-size: 1.25rem;
            }
        }

        .navbar-toggler {
            border-color: transparent;
            border: 0;
            margin-right: 1rem;
        }

        .navbar-collapse {
            // background: $primary;
            .navbar-nav {
                .nav-item {
                    padding: .5rem 0;
    
                    a.nav-link {
                        padding: 0 20px;
                    }
                }
            }
        }
    }
}

.navbar-sticky {
    background-color: $primaryDark !important;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
    height: 70px;
    left: 0;
    top: 0;

    .navbar-brand {
        margin-left: 1rem;
        height: 70px;
        width: 70px;
        position: relative;

        @include phone {
            margin-left: 0;
        }

        img {
            // filter: invert(1);
            position: absolute;
            top: 0;
            width: 100%;
            height: 60px;
        }

        .navbar-logo-text {
            margin-left: 1rem;

            .brand-text {
                transition: all 250ms ease;
                font-size: 1.625rem;
                line-height: 1.25;

                @include phone {
                    font-size: 1.5rem;
                }
            }
            .brand-subtext {
                transition: all 250ms ease;
                font-size: 0.775rem;
                @include phone {
                    font-size: 0.775rem;
                }
            }
        }
    }

    .navbar-nav {
        .nav-item {
            transition: all 250ms ease;
            padding: 20px 0;

            a.nav-link {
                transition: all 250ms ease;
                padding: 0 25px;
                color: white;
                font-size: 1rem;

                // &:hover {
                    // color: $third;
                // }
            }

            // a.active {
                // color: $third;
            // }
        }

        // .nav-item.active {

            // a.nav-link,
            // a.active {
                // color: $third;
        //     }
        // }
    }

    @include phone {
        .navbar-brand {

            img {
                width: auto;
                height: 48px;
            }

            .navbar-logo-text {
                transition: all 250ms ease;
                font-size: 1.25rem;
            }
        }

        .navbar-toggler {
            border-color: transparent;
            border: 0;
        }
    }

    @include tablet {
        .navbar-brand {

            img {
                height: 2rem;
            }

            .navbar-logo-text {
                transition: all 250ms ease;
                font-size: 1.25rem;
            }
        }

        .navbar-toggler {
            border-color: transparent;
            border: 0;
        }
    }
}

.navbar-page {
    // background-color: white !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);

    .navbar-brand {
        margin-left: 1rem;

        img {
            transition: all 250ms ease;
            height: 2rem;
        }

        .navbar-logo-text {
            transition: all 250ms ease;
            font-size: 1.25rem;
        }
    }

    .navbar-nav {
        .nav-item {
            transition: all 250ms ease;
            padding: 20px 0;

            a.nav-link {
                padding: 0 20px;
                color: white;
                font-size: 1rem;
                // @extend .font-weight-medium;

                // &:hover {
                    // color: $third;
                // }
            }

            // a.active {
                // color: $third;
            // }
        }

        // .nav-item.active {

        //     a.nav-link,
        //     a.active {
                // color: $third;
        //     }
        // }
    }

    @include phone {
        .navbar-brand {

            img {
                height: 2.5rem;
            }

            .navbar-logo-text {
                transition: all 250ms ease;
                font-size: 1.25rem;
            }
        }

        .navbar-toggler {
            border-color: transparent;
            border: 0;
        }
    }

    @include tablet {
        .navbar-brand {

            img {
                height: 2rem;
            }

            .navbar-logo-text {
                transition: all 250ms ease;
                font-size: 1.25rem;
            }
        }

        .navbar-toggler {
            border-color: transparent;
            border: 0;
        }
    }
}

@include phone {
    .navbar {
        .container-fluid {
            justify-content: start;

            .navbar-toggler {
                padding-left: 0;
                margin-right: 0.5rem;

                &:focus:not(:focus-visible) {
                    outline: 0;
                    box-shadow: none;
                }
            }
        }
    }
}

.offcanvas-body {
    .nav-link:before {
        content: "";
        width: 0;
        height: 2px;
        background-color: white;
        position: absolute;
        left: 0;
        top: 50%;
        transition: all 300ms;
    }

    .nav-link.active:before {
        content: "";
        width: 16px;
        height: 2px;
        background-color: white;
        position: absolute;
        left: 0;
        top: 50%;
        transition: all 300ms;
    }

    .nav-link:hover:before {
        content: "";
        width: 16px;
        height: 2px;
        background-color: white;
        position: absolute;
        left: 0;
        top: 50%;
        transition: all 300ms;
    }
}