@import "resources/sass/components/color.scss";
@import "resources/sass/components/font.scss";
$container-max-widths: (
    xxl: 1140px
);
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../public/plugins/bower_components/slick-carousel/slick/slick.scss";
@import "resources/sass/components/mq.scss";
@import "resources/sass/components/button.scss";
@import "resources/sass/components/footer.scss";
@import "resources/sass/layouts/navbar.scss";
@import "resources/sass/global.scss";