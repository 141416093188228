$primary: #262626;
$primaryLight: #595959;
$primaryDark: #181818;
$secondary: #FFFFFF;
$blue: #4141FF;
$theme-colors: () !default;
$theme-colors: map-merge((
    "primary": $primary,
    "primary-light": $primaryLight,
    "primary-dark": $primaryDark,
    "secondary": $secondary,
    "blue": $blue,
), $theme-colors);